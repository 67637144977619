import React, { useState } from 'react';
import styled from 'styled-components';
import Text from '../atoms/Text';
import { Link } from 'gatsby';

const Wrapper = styled.section`
  margin: 0 auto;
  padding: 0 15px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }
  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const AccordionBox = styled.div`
  position: relative;
  margin-bottom: 50px;
  width: 100%;
`;

const AccordionWrapper = styled.div`
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  transition: all 0.5s ${({ theme }) => theme.easing1};
  box-shadow: 0px 16px 50px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;

  &.show {
    height: auto;
    max-height: 9999px;
    /* margin-top: 50px; */
    margin-bottom: 20px;
    transition: all 0.5s ${({ theme }) => theme.easing1};
  }

  @media (min-width: ${({ theme }) => theme.tablet}) {
    display: flex;
    /* padding: 50px; */
  }
`;

const PriceWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    align-items: flex-start;
    width: 30%;
  }
`;

const AbovePrice = styled(Text)`
  color: #4f4f4f;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: ${({ theme }) => theme.medium};
`;

const Price = styled(Text)`
  display: inline-block;
  font-size: 20px;
  font-weight: ${({ theme }) => theme.bold};
  color: #000;
`;

const SmallPrice = styled.span`
  font-size: 12px;
  font-weight: ${({ theme }) => theme.light};
  color: #000;
`;

const ContentWrapper = styled.div`
  &::after {
    content: '';
    background: linear-gradient(
      180deg,
      rgba(255, 255, 255, 0) 0%,
      #ffffff 100%
    );
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    transition: all 0.5s ${({ theme }) => theme.easing1};
  }

  &.show {
    &::after {
      opacity: 0;
    }
  }
  @media (min-width: ${({ theme }) => theme.tablet}) {
    width: 70%;

    &::after {
      left: 30%;
      width: 70%;
    }
  }
`;

const ContentText = styled(Text)`
  /* font-size: 18px; */
  margin-bottom: 10px;

  ul {
    padding-left: 25px;
  }

  p,
  ul {
    margin-bottom: 15px;
  }
`;

const ShowButton = styled.span`
  position: absolute;
  bottom: -30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 16px;
  text-decoration: underline;
  z-index: 10;
  cursor: pointer;
`;

const LinkButton = styled(Link)`
  width: 180px;
  height: 60px;
  background-color: ${({ theme }) => theme.primary};
  color: #fff;
  font-size: ${({ theme }) => theme.size20};
  font-weight: ${({ theme }) => theme.semiBold};
  text-transform: uppercase;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Pricing = ({ data }) => {
  const [selected, setSelected] = useState(null);

  const toggle = (i) => {
    if (selected == i) {
      return setSelected(null);
    }

    setSelected(i);
  };
  return (
    <Wrapper>
      <AccordionBox>
        <AccordionWrapper className={selected == 1 && 'show'}>
          <ShowButton onClick={() => toggle(1)}>
            {selected === 1 ? 'Zwiń' : 'Rozwiń'}
          </ShowButton>
          <PriceWrapper>
            <AbovePrice>{data.datoCmsConsulting.package[0].name}</AbovePrice>
            <Price>
              {data.datoCmsConsulting.package[0].price}
              {' PLN '}
              <SmallPrice>netto</SmallPrice>
            </Price>
          </PriceWrapper>
          <ContentWrapper className={selected == 1 && 'show'}>
            <ContentText
              dangerouslySetInnerHTML={{
                __html: data.datoCmsConsulting.package[0].description,
              }}
            />
            {/* <ContentText>1. Analiza umowy – do 30 stron:</ContentText>
            <ContentText>
              <ul>
                <li>przedwstępnej</li>
                <li>deweloperskiej</li>
                <li>cesji</li>
                <li>sprzedaży</li>
                <li>przeniesienia praw własności</li>
              </ul>
            </ContentText>
            <ContentText>2. Weryfikacja:</ContentText>
            <ContentText>
              <ul>
                <li>czy umowa zawiera klauzule niedozwolone</li>
                <li>
                  niekorzystnych postanowień umownych wraz z propozycją ich
                  zmiany
                </li>
              </ul>
            </ContentText>
            <ContentText>
              3. Wprowadzenie uwag i rekomendacji w trybie recenzji bezpośrednio
              na nadesłanym dokumencie umowy.
            </ContentText> */}
          </ContentWrapper>
        </AccordionWrapper>
      </AccordionBox>

      {/*  */}

      <AccordionBox>
        <AccordionWrapper className={selected == 2 && 'show'}>
          <ShowButton onClick={() => toggle(2)}>
            {selected === 2 ? 'Zwiń' : 'Rozwiń'}
          </ShowButton>
          <PriceWrapper>
            <AbovePrice>{data.datoCmsConsulting.package[1].name}</AbovePrice>
            <Price>
              {data.datoCmsConsulting.package[1].price}
              {' PLN '} <SmallPrice>netto</SmallPrice>
            </Price>
          </PriceWrapper>
          <ContentWrapper className={selected == 2 && 'show'}>
            <ContentText
              dangerouslySetInnerHTML={{
                __html: data.datoCmsConsulting.package[1].description,
              }}
            />
            {/* <ContentText>1. Analiza umowy:</ContentText>
            <ContentText>
              <ul>
                <li>przedwstępnej</li>
                <li>deweloperskiej</li>
                <li>cesji</li>
                <li>sprzedaży</li>
                <li>przeniesienia praw własności</li>
              </ul>
            </ContentText>
            <ContentText>2. Weryfikacja:</ContentText>
            <ContentText>
              <ul>
                <li>czy umowa zawiera klauzule niedozwolone</li>
                <li>
                  niekorzystnych postanowień umownych wraz z propozycją ich
                  zmiany
                </li>
                <li>księgi wieczystej dostępnej w wersji elektronicznej</li>
                <li>
                  dewelopera na podstawie danych zawartych w odpowiednich
                  rejestrach i urzędach, tj. KRS, CIDG i UKOiK
                </li>
              </ul>
            </ContentText>
            <ContentText>
              3. Wprowadzenie uwag i rekomendacji w trybie recenzji bezpośrednio
              na nadesłanym dokumencie umowy.
            </ContentText>
            <ContentText>
              4. Konsultacja prawna i wsparcie w negocjacjach (z deweloperem,
              kancelarią notarialną i/lub sprzedawcą).
            </ContentText> */}
          </ContentWrapper>
        </AccordionWrapper>
      </AccordionBox>

      {/*  */}

      <AccordionBox>
        <AccordionWrapper className={selected == 3 && 'show'}>
          <ShowButton onClick={() => toggle(3)}>
            {selected === 3 ? 'Zwiń' : 'Rozwiń'}
          </ShowButton>
          <PriceWrapper>
            <AbovePrice>{data.datoCmsConsulting.package[2].name}</AbovePrice>
            <Price>
              {data.datoCmsConsulting.package[2].price}
              {' PLN '} <SmallPrice>netto</SmallPrice>
            </Price>
          </PriceWrapper>
          <ContentWrapper className={selected == 3 && 'show'}>
            <ContentText
              dangerouslySetInnerHTML={{
                __html: data.datoCmsConsulting.package[2].description,
              }}
            />
            {/* <ContentText>1. Analiza umowy:</ContentText>
            <ContentText>
              <ul>
                <li>przedwstępnej</li>
                <li>deweloperskiej</li>
                <li>cesji</li>
                <li>ekspektatywy</li>
                <li>sprzedaży</li>
                <li>przeniesienia praw własności</li>
              </ul>
            </ContentText>
            <ContentText>2. Weryfikacja:</ContentText>
            <ContentText>
              <ul>
                <li>czy umowa zawiera klauzule niedozwolone</li>
                <li>
                  niekorzystnych postanowień umownych wraz z propozycją ich
                  zmiany
                </li>
                <li>księgi wieczystej dostępnej w wersji elektronicznej</li>
                <li>
                  dewelopera na podstawie danych zawartych w odpowiednich
                  rejestrach i urzędach, tj. KRS, CIDG i UKOiK
                </li>
                <li>
                  prospektu informacyjnego pod kątem jego zgodności z ustawą
                </li>
                <li>bezpieczeństwa inwestycji oraz wskazanie możliwych ryzk</li>
              </ul>
            </ContentText>
            <ContentText>
              3. Wprowadzenie uwag i rekomendacji w trybie recenzji bezpośrednio
              na nadesłanym dokumencie umowy.
            </ContentText>
            <ContentText>
              4. Propozycja dodatkowych postanowień umownych.
            </ContentText>
            <ContentText>
              5. Dwie konsultacje prawne, wsparcie w negocjacjach (z
              deweloperem, kancelarią notarialną i/lub sprzedawcą) oraz na
              życzenie klienta obecność podczas podpisania aktu notarialnego.
            </ContentText> */}
          </ContentWrapper>
        </AccordionWrapper>
      </AccordionBox>
      <LinkButton
        to='/oferta/wyszukanie-i-stworzenie-mieszkania-zgodnie-z-oczekiwaniami'
        state={{ isPricing: true }}
      >
        Kontakt
      </LinkButton>
    </Wrapper>
  );
};

export default Pricing;
