import React from 'react';
import MainTemplate from '../../templates/MainTemplate';
import SubpageHeader from '../../components/organisms/SubpageHeader';
import Headline from '../../components/atoms/Headline';
import styled from 'styled-components';
import Text from '../../components/atoms/Text';
import Pricing from '../../components/molecules/Pricing';
import { GatsbyImage } from 'gatsby-plugin-image';
import { graphql, useStaticQuery } from 'gatsby';
import ContactButton from '../../components/atoms/ContactButton';
import TherWorkWithUs from '../../components/organisms/TheyWorkWithUs';

const FirstSection = styled.section`
  margin: 100px auto 100px auto;
  padding: 0 15px;
  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    padding: 0 5%;
  }

  @media (min-width: ${({ theme }) => theme.desktop}) {
    padding: 0;
  }
`;

const FirstSectionContent = styled.div`
  display: flex;
  flex-direction: column;

  @media (min-width: ${({ theme }) => theme.tablet}) {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const FirstSectionContentLeft = styled.div`
  .content-paragraph {
    p {
      margin-bottom: 30px;
    }
  }
  @media (min-width: ${({ theme }) => theme.tablet}) {
    width: 45%;
  }
`;

const FirstSectionContentRight = styled.div`
  .content-paragraph {
    p {
      margin-bottom: 30px;
    }
  }

  .gatsby-image-wrapper {
    margin-bottom: 30px;
  }

  &.isReversed {
    display: flex;
    flex-direction: column-reverse;
  }
  @media (min-width: ${({ theme }) => theme.tablet}) {
    width: 50%;

    &.isReversed {
      flex-direction: column;
    }
  }
`;

const ArrowsContainer = styled.div`
  margin-top: 15px;
  width: 100%;
  margin-bottom: 50px;
  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    display: flex;
    justify-content: space-between;
  }
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;

  &:not(:last-child) {
    margin-bottom: 15px;
  }

  @media (min-width: ${({ theme }) => theme.smLaptop}) {
    max-width: 280px;
    margin-bottom: 0 !important;
  }
  @media (min-width: ${({ theme }) => theme.desktop}) {
    max-width: 320px;
    margin-bottom: 0;
  }
`;

const Icon = styled.div`
  width: 38px !important;
  height: 38px !important;
  min-width: 38px;
  flex-basis: 38px;
  flex-grow: 0;
  flex-shrink: 0;
  background-color: ${({ theme }) => theme.yellow};
  flex-basis: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 8px;
`;

const IconContent = styled.div``;

const IconText = styled.p`
  font-size: ${({ theme }) => theme.size14};
  font-weight: ${({ theme }) => theme.light};
  /* color: white; */
`;

const ContactTextInfo = styled.p`
  font-size: ${({ theme }) => theme.size15};
  font-weight: ${({ theme }) => theme.medium};
  text-align: center;
  margin-bottom: 20px;
  margin-top: 30px;

  @media (min-width: ${({ theme }) => theme.laptop}) {
    font-size: ${({ theme }) => theme.size16};
  }
`;

const DoradztwoPage = () => {
  const data = useStaticQuery(query);
  const icons = [
    'Podatek PCC',
    'Podatek VAT',
    'Podatek dochodowy',
    'Składka zdrowotna',
  ];
  return (
    <MainTemplate
      isMainPage={false}
      title='Doradztwo prawne i podatkowe'
      metaDescription='Dokonamy oceny i analizy zarówno umowy deweloperskiej, cesji umowy
    deweloperskiej jak i umowy Sprzedaży nieruchomości (gruntowej,
    budynkowej i lokalowej)'
    >
      <SubpageHeader
        headline='Doradztwo prawne i podatkowe'
        bgImage='isDoradztwo'
      />
      <FirstSection id='doradztwo-prawne'>
        <Headline
          data-aos='headline-fadeup'
          data-aos-anchor='#doradztwo-prawne'
          style={{ textAlign: 'center', marginBottom: '50px' }}
        >
          {data.datoCmsConsulting.firstSectionHeadline}
        </Headline>
        <FirstSectionContent>
          <FirstSectionContentLeft>
            <Text
              style={{ marginBottom: '30px' }}
              className='content-paragraph'
              dangerouslySetInnerHTML={{
                __html: data.datoCmsConsulting.firstSectionTextLeft,
              }}
            />
          </FirstSectionContentLeft>
          <FirstSectionContentRight className='isReversed'>
            <GatsbyImage
              image={data.lawyer.childImageSharp.gatsbyImageData}
              alt='Doradztwo podatkowe'
            />
            <Text
              style={{ marginBottom: '30px' }}
              className='content-paragraph'
              dangerouslySetInnerHTML={{
                __html: data.datoCmsConsulting.firstSectionTextRight,
              }}
            />
          </FirstSectionContentRight>
        </FirstSectionContent>
      </FirstSection>
      <Pricing data={data} />
      <FirstSection id='doradztwo-podatkowe'>
        <Headline
          data-aos='headline-fadeup'
          data-aos-anchor='#doradztwo-podatkowe'
          style={{ textAlign: 'center', marginBottom: '50px' }}
        >
          {data.datoCmsConsulting.thirdSectionHeadline}
        </Headline>
        <FirstSectionContent>
          <FirstSectionContentLeft>
            <Text
              style={{ textAlign: 'justify', marginBottom: '30px' }}
              data-aos='headline-fadeup'
              data-aos-delay='200'
              data-aos-anchor='#doradztwo-podatkowe'
              dangerouslySetInnerHTML={{
                __html: data.datoCmsConsulting.thirdSectionTextLeft,
              }}
            />
            <Text
              style={{ textAlign: 'justify', marginBottom: '30px' }}
              data-aos='headline-fadeup'
              data-aos-delay='400'
              data-aos-anchor='#doradztwo-podatkowe'
              dangerouslySetInnerHTML={{
                __html: data.datoCmsConsulting.thirdSectionTextRight,
              }}
            />
            {/* <Text
              style={{ textAlign: "justify", marginBottom: "30px" }}
              data-aos="headline-fadeup"
              data-aos-delay="200"
              data-aos-anchor="#second-trigger"
            >
              Podatki są ściśle związane z zakupem i sprzedażą mieszkania czy to
              z rynku wtórnego czy pierwotnego, a określenie ich prawidłowej
              wysokości jest niebagatelnie ważne z punktu widzenia kupującego
              oraz sprzedawcy. Doświadczenie w tym zakresie pokazuje, że jest to
              temat skomplikowany i niosący za sobą duże ryzko finansowe, które
              należy minimalizować.
            </Text> */}
          </FirstSectionContentLeft>
          <FirstSectionContentRight>
            <GatsbyImage
              image={data.doradztwo.childImageSharp.gatsbyImageData}
              alt='Doradztwo podatkowe'
            />
            {/* <Text
              style={{ textAlign: "justify", marginBottom: "30px" }}
              data-aos="headline-fadeup"
              data-aos-delay="400"
              data-aos-anchor="#second-trigger"
            >
              Konsultacja podatkowa w zakresie podatku dochodowego, PCC i VAT
              dotycząca zakupu/sprzedaży nieruchomości mieszkalnej dla osób
              fizycznych oraz prawnych w cenie 250 złotych netto – z całą
              pewnością rozwieje twoje wszelkie wątpliwości, niejasności i
              zawiłości interpretacyjnej urzędów skarbowych.
            </Text> */}
          </FirstSectionContentRight>
        </FirstSectionContent>
        <ArrowsContainer>
          {icons.map((edge, index) => (
            <IconBox key={index}>
              <Icon>
                <svg
                  width='11'
                  height='19'
                  viewBox='0 0 11 19'
                  fill='none'
                  xmlns='http://www.w3.org/2000/svg'
                >
                  <path
                    d='M0.381165 16.8246L1.99026 18.4337L10.9903 9.43365L1.99026 0.433655L0.381165 2.04275L7.77207 9.43365L0.381165 16.8246Z'
                    fill='white'
                  />
                </svg>
              </Icon>
              <IconContent>
                <IconText>{edge}</IconText>
              </IconContent>
            </IconBox>
          ))}
        </ArrowsContainer>
        <ContactTextInfo>
          Skontaktuj się z nami, a odpowiemy na wszystkie Twoje pytania
        </ContactTextInfo>
        <ContactButton />
      </FirstSection>
      <TherWorkWithUs />
    </MainTemplate>
  );
};

const query = graphql`
  query DoradztwoQuery {
    doradztwo: file(name: { eq: "Doradztwo-podatkowe" }) {
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: NONE, quality: 100)
      }
    }
    lawyer: file(name: { eq: "prawnik" }) {
      childImageSharp {
        gatsbyImageData(formats: WEBP, placeholder: NONE, quality: 100)
      }
    }
    datoCmsConsulting {
      firstSectionHeadline
      firstSectionTextLeft
      firstSectionTextRight
      package {
        name
        price
        description
      }
      thirdSectionHeadline
      thirdSectionTextLeft
      thirdSectionTextRight
      title
    }
  }
`;

export default DoradztwoPage;
